<template>
	<div>
		<a-crm-container>
			<template>
				<div class='a-crm-content'>
					<div class='a-crm-content-box'>
						<ValidationObserver ref="form" v-slot="{ invalid }">
							<form @submit.prevent='onSubmit'>
								<div>
									<div class='a-crm-content-group'>
										<p class='a-sub-heading a-crm-content-title'>{{$t("crm.your_data")}}</p>
										<div class="row">
											<div class="col-4">
												<ValidationProvider :name='$t("account.trips.phone")'
													:rules="'required|numeric|length:12'"
													v-slot='{errors}'>
														<v-text-field
															:label='$t("account.trips.phone")'
															placeholder="+380-67-764-54-45"
															v-model="form.phone"
															name='phone'
															required
															mask="+380 ## ### ## ##"
															:errorMessage='errors[0]'
														/>
												</ValidationProvider>
											</div>
											<div class="col-4">
												<v-text-field
													label='E-mail'
													placeholder="email@com"
													v-model="form.email"
													type='email'
													rules="required|email"
												/>
											</div>
											<div class="col-4">
												<v-text-field
													:label='$t("crm.telegram_share")'
													placeholder="+380-00-000-00-00"
													v-model="form.telegram"
													rules="numeric|length:12"
													mask="+380 ## ### ## ##"
												/>
											</div>
											<div class="col-4">
												<v-text-field
													:label='$t("crm.viber_share")'
													placeholder="+380-00-000-00-00"
													v-model="form.viber"
													rules="numeric|length:12"
													mask="+380 ## ### ## ##"
												/>
											</div>
											<div class="col-4">
												<v-text-field
													:label='$t("crm.facebook_profile")'
													placeholder="https://facebook/test.agent"
													v-model="form.fb"
												/>
											</div>
											<div class="col-8">
												<v-text-field
													:label='$t("crm.address")'
													placeholder="Київ, ул. Грушевського 4"
													v-model="form.address"
												/>
											</div>
										</div>
									</div>
									<div class='a-crm-content-group'>
										<p class='a-sub-heading a-crm-content-title'>{{$t("crm.your_password")}}</p>
										<div class="row">
											<div class="col-4">
												<v-text-field
													:label='$t("crm.password")'
													type='password'
													v-model="form.password"
													:errorMessage='errorPassword'
												/>
											</div>
											<div class="col-4">
												<v-text-field
													:label='$t("crm.password_confirm")'
													type='password'
													v-model="form.password_confirm"
													:errorMessage='errorPassword'
												/>
											</div>
										</div>
									</div>
								</div>
								<div class='a-crm-content-group-checkbox'>
									<v-checkbox
										name='terms_check'
										:label='$t("crm.label")'
										v-model='form.agreement'
									/>
								</div>
								<div class='a-crm-content-group-buttons'>
									<v-button xxsmall
										color="green-persian white--text"
										:disabled="invalid || !form.agreement"
										@click='onSubmit'
										:loading='loading'
									>{{$t("account.buttons.save")}}</v-button>
								</div>
							</form>
						</ValidationObserver>
					</div>
				</div>
			</template>
		</a-crm-container>
	</div>
</template>

<script>
	import ACrmContainer from '@/views/crm/container';
	import {cabinet} from "@/services/request";

	export default {
		data: () => ({
			invalid: false,
			loading: false,
			errorPassword: '',
			form:{
				email: null,
				phone: null,
				address: null,
				fb: null,
				telegram: null,
				viber: null,
				agreement: false,
				password: '',
				password_confirm: '',
			},
		}),
		components: {
			ACrmContainer
		},
		created() {
			this.getData();
		},
		methods: {
			onSubmit() {
				if(this.form.password !== this.form.password_confirm){
					this.errorPassword = this.$t('crm.password_does_not_match')
					return;
				}

				this.loading = true;
				cabinet.user.update(this.form).then(() => {
					this.$store.commit('notification/successMessage', {title: this.$t("crm.your_profile_has_been_successfully_updated")})
				}).finally(() => {
					this.loading = true;
				})
			},
			getData(){
				cabinet.user.get().then(r => {
					Object.keys(r.data).map(key => {this.form[key] = r.data[key]});
				})
			}
		}
	}
</script>
